/*
 * Copyright 2024 Tridium Inc. All rights reserved.
 */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ActiveEffectButton,
  NiagaraButton2,
  NiagaraCard,
  NiagaraSearch,
  NiagaraTooltip,
} from '@Niagara-Cloud-Suite/Niagara-Cloud-Suite.NiagaraManagementPlaneCommons-lib';
import Status from '../utils/Status';
import { useProjectStore } from './UseProjectStore';
import { DataTable } from '@scuf/datatable';
import '@scuf/datatable/honeywell/theme.css';
import { useDevicesPageAndSearch } from './UseDevicesPageAndSearch';
import { IPageSearchSortOptions } from './IPageSearchSortOptions';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { DeleteProjectModal } from './ProjectListSidebarEntry';
import { showErrorToast, showSuccessToast, ToastActions } from '../toast/Toast';
import { GetFailedResponseMessage, GetSuccessResponseMessage } from './EditDeviceDetails/PopupMessages';
import { DeviceListPopupMenu } from './DeviceListPopupMenu';
import { ScrollIntoView } from '../components/ScrollIntoView/ScrollIntoView';
import { showSnackBarProp, SnackBarProp } from '../components/TypeDefinitions';
import { Device, Project } from '../api/management';
import { DeviceWithProjectIdAndSubscriptions } from './ProjectStore';
import { NiagaraDataTable } from '@Niagara-Cloud-Suite/Niagara-Cloud-Suite.NiagaraManagementPlaneCommons-lib/lib/table';
import { tagTimedEvent } from '../utils/localytics/useAnalytics';
import { ConfirmModal } from '../components/ConfirmModal/ConfirmModal';
import { usePermissions } from '../userroles/usePermissions';
import { useHistoryState } from '../utils/useHistoryState';
import HeaderCell, { HeaderCellProps } from '../components/HeaderCell/HeaderCell';
import { NavLink, useNavigate } from 'react-router-dom';
import { getDeviceRemoteUrl } from '../utils/getDeviceRemoteUrl';
import { UsageMetricsModal } from './usageMetricsModal/UsageMetricsModal';
import { EditDeviceDetailsModal } from './EditDeviceDetails/EditDeviceDetailsModal';
import { UsageMetricsModalV2 } from './usageMetricsModal/UsageMetricsModalV2';
import { ReRegisterDeviceModal } from './DeviceRegistration/ReRegisterDeviceModal';
import { reRegisterDeviceAsync } from '../api/registerDeviceAsync';
import { TableColumnTooltip } from '../components/TableColumnTooltip';
import { DevicePointPair } from '../dataServices/DevicePointPair';
export interface IDeviceUpdateNotification {
  newDevicePromise: Promise<Device>;
  updatedFields: {
    projectId?: number;
    projectName?: string;
    location?: string;
  };
}
const ITEMS_PER_PAGE = 15;
export default function DeviceList({
  SnackBar,
  showSnackBar,
}: {
  SnackBar: SnackBarProp;
  showSnackBar: showSnackBarProp;
}) {
  const { currentProject, getProjectsAndDevicesAsync, setCurrentProject, setCurrentDevice, deleteDevice } =
    useProjectStore();
  const [highlightedDeviceId, setHighlightedDeviceId] = useState<number>();
  const [projectToDelete, setProjectToDelete] = useState<Project>();
  const [deviceToShowUsage, setDeviceToShowUsage] = useState<DeviceWithProjectIdAndSubscriptions>();
  const [deviceToReregister, setDeviceToReregister] = useState<DeviceWithProjectIdAndSubscriptions>();
  const [selectedDevice, setSelectedDevice] = useState<DeviceWithProjectIdAndSubscriptions>();
  const [deviceToDelete, setDeviceToDelete] = useState<DeviceWithProjectIdAndSubscriptions>();
  const [deviceToEdit, setDeviceToEdit] = useState<DeviceWithProjectIdAndSubscriptions>();
  const { enableDeviceDetailsPage } = useFlags();
  const [settings, setSettings] = useHistoryState<{
    projectId?: string;
    page?: number;
  }>();

  const [options, setOptions] = useState<IPageSearchSortOptions<Device>>(() => {
    return {
      keyword: undefined,
      page: (currentProject?.id === settings.projectId ? settings.page : null) ?? 0,
      size: ITEMS_PER_PAGE,
      sort: ['deviceName'],
      order: 'asc',
    };
  });

  const goToPage = useCallback((page: number) => {
    setOptions((o) => ({ ...o, page }));
    setSettings({ page });
  }, []);
  const onPageChange = useCallback((page: string | number) => goToPage(parseInt(page.toString(), 10) - 1), []);
  const setKeyword = useCallback((keyword?: string) => setOptions((o) => ({ ...o, keyword })), []);
  const setOrder = useCallback(
    (order: string) => setOptions((o) => ({ ...o, order: order === 'asc' ? 'asc' : 'desc' })),
    []
  );
  const setSortBy = useCallback((fieldName: keyof Device) => setOptions((o) => ({ ...o, sort: [fieldName] })), []);

  const { status = 'idle', page, devices = [], findDevicePage, error } = useDevicesPageAndSearch(options);

  const ldc = useLDClient();
  const [disabledDevicesforAlarms, setDisabledDevicesforAlarms] = useState<string[]>([]);

  useEffect(() => {
    if (!page) return;
    goToPage(findDevicePage(highlightedDeviceId) ?? page?.number);
  }, [highlightedDeviceId, page?.number]);

  useEffect(() => {
    devices.map((d, i) => {
      return ldc
        ?.identify({
          kind: 'user',
          key: d.id.toString(),
          deviceUuid: d.deviceUuid,
        })
        .then((allFlags) => {
          if (!allFlags.enableDeviceAlarms) {
            disabledDevicesforAlarms.push(d.deviceUuid);
            setDisabledDevicesforAlarms(disabledDevicesforAlarms);
          }
        });
    });
  }, [devices.length]);

  const hasDevices =
    (devices && devices.length > 0) ||
    (!currentProject?.devices && options.keyword) ||
    (currentProject?.devices && currentProject?.devices?.length > 0);

  const onDeviceDetailsUpdate = ({ newDevicePromise, updatedFields }: IDeviceUpdateNotification) => {
    const { projectName } = updatedFields;
    newDevicePromise
      .then((updatedDevice) => {
        setHighlightedDeviceId(updatedDevice.id);
        const message = GetSuccessResponseMessage(updatedDevice, updatedFields);
        !projectName
          ? showSnackBar(<div className='device-update-snackbar-message'>{message}</div>)
          : showSuccessToast(
              '',
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className='device-update-toast-message'>{message}</div>
                <ToastActions
                  secondaryText='View Device'
                  secondaryAction={() => {
                    setCurrentProject(updatedFields.projectId);
                    setSettings({
                      projectId: updatedFields.projectId,
                      page: 0,
                    });
                    setHighlightedDeviceId(updatedDevice.id);
                  }}
                  secondaryButtonPosition='right'
                />
              </div>
            );
      })
      .catch(() => {
        const message = GetFailedResponseMessage(updatedFields);
        !projectName
          ? showSnackBar(
              <div style={{ display: 'flex', gap: '10px' }}>
                <div className='device-update-snackbar-message'>{message}</div>
                <NiagaraButton2
                  className='device-update-snackbar-retry-button'
                  type='primary-link'
                  content='Retry'
                  onClick={() => {
                    onDeviceDetailsUpdate({ newDevicePromise, updatedFields });
                  }}
                />
              </div>,
              0
            )
          : showErrorToast(
              '',
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className='device-update-toast-message'>{message}</div>
                <NiagaraButton2
                  className='device-update-toast-retry-button'
                  type='primary-link'
                  content='Retry'
                  onClick={() => {
                    onDeviceDetailsUpdate({ newDevicePromise, updatedFields });
                  }}
                />
              </div>
            );
      });
    setDeviceToEdit(undefined);
  };

  const onConfirmDeleteDevice = () => {
    const { complete } = tagTimedEvent('Delete Device');
    deviceToDelete &&
      deleteDevice(deviceToDelete.id, deviceToDelete.projectId)
        .then(() => {
          complete({ status: 'success' });
          showSuccessToast('Device Deleted', `Device '${deviceToDelete.deviceName}' was deleted.`);
        })
        .catch(() => {
          complete({ status: 'failed' });
          showErrorToast(
            '',
            <div className='device-delete-toast'>
              {`Failed to delete ${deviceToDelete.deviceName}`}
              <NiagaraButton2 type='primary-link' content='Retry' onClick={() => onConfirmDeleteDevice()} />
            </div>
          );
        });
    setDeviceToDelete(undefined);
  };
  const DevicePermissions = usePermissions().Management.Devices;
  const headerProps: Partial<HeaderCellProps<Device>> = {
    order: options.order,
    setSortBy,
    setOrder,
    sortBy: options.sort?.[0] as keyof Device,
  };

  return (
    <NiagaraCard className='device-list'>
      <SnackBar />
      <Status status={status} error={error} onRefresh={getProjectsAndDevicesAsync}>
        <div className='device-list-header'>
          <div className='card-title flex-fill'>Devices</div>
          {hasDevices ? (
            <NiagaraSearch placeholder='Search' value={options.keyword} results={[]} onSearchChange={setKeyword} />
          ) : null}
        </div>
        {hasDevices ? (
          <>
            <NiagaraDataTable
              paginationSettings={{
                onPageChange: (e) => {
                  onPageChange(e);
                  highlightedDeviceId && setHighlightedDeviceId(undefined);
                  selectedDevice && setSelectedDevice(undefined);
                },
                totalItems: page?.totalElements ?? 0,
                itemsPerPage: page?.size ?? ITEMS_PER_PAGE,
                activePage: (page?.number ?? 0) + 1,
              }}
              data={devices}
              enableLazyPagination={true}
              showItemCountAtBottom={true}
              tableName='Device'
            >
              <DataTable.Column
                initialWidth='200px'
                field='deviceName'
                header={<HeaderCell name='Device' sortOnField='deviceName' {...headerProps} />}
                renderer={({ rowData: device }: { rowData: Device }) =>
                  deviceNameRenderer(device, highlightedDeviceId, enableDeviceDetailsPage, setCurrentDevice)
                }
              />

              <DataTable.Column
                initialWidth='200px'
                field='hostId'
                renderer={({ rowData: device }) => (
                  <div>
                    <TableColumnTooltip columnData={device.hostId} />
                  </div>
                )}
                header={<HeaderCell name='Host ID' sortOnField='hostId' {...headerProps} />}
              />
              <DataTable.Column
                initialWidth='200px'
                field='projectName'
                header={<HeaderCell name='Project' sortOnField='projectName' {...headerProps} />}
              />
              <DataTable.Column
                initialWidth='200px'
                field='location'
                header={<HeaderCell name='Location' sortOnField='location' {...headerProps} />}
              />
              <DataTable.Column
                initialWidth='200px'
                field='services'
                header='Services'
                renderer={({ rowData: device }) => (
                  <Services {...{ device, disabledDevicesforAlarms, setCurrentDevice }} />
                )}
              />
              <DataTable.Column
                field=''
                header=''
                className='action-column'
                renderer={({ rowData: device }: { rowData: DeviceWithProjectIdAndSubscriptions }) => (
                  <DeviceListPopupMenu
                    onShowUsage={
                      device.subscriptions.some((o) => o.offeringCode === 'NDS')
                        ? () => setDeviceToShowUsage(device)
                        : undefined
                    }
                    onEdit={DevicePermissions.EditDevice(device) ? () => setDeviceToEdit(device) : undefined}
                    onDelete={DevicePermissions.DeleteDevice(device) ? () => setDeviceToDelete(device) : undefined}
                    onReregister={
                      DevicePermissions.RegisterDevice(device) ? () => setDeviceToReregister(device) : undefined
                    }
                  />
                )}
              />
            </NiagaraDataTable>
            {deviceToShowUsage ? (
              <UsageMetricsModalV2
                device={deviceToShowUsage}
                onClose={() => {
                  setDeviceToShowUsage(undefined);
                }}
              />
            ) : null}
            {deviceToReregister && (
              <ReRegisterDeviceModal
                device={deviceToReregister}
                onClose={() => {
                  setDeviceToReregister(undefined);
                }}
                onReRegisterDevice={(userCode: string, licenseId: string) => {
                  reRegisterDeviceAsync({
                    userCode,
                    deviceUuid: deviceToReregister.deviceUuid ?? '',
                    licenseId: parseInt(licenseId),
                  })
                    .then(() => showSuccessToast('Device re-registered.'))
                    .catch(() => {
                      showErrorToast(
                        'Device re-registration failed!',
                        <>Failed to re-register device. Please, try again after sometime</>
                      );
                    });
                  setDeviceToReregister(undefined);
                }}
              />
            )}
            {deviceToEdit && DevicePermissions.EditDevice(deviceToEdit) && (
              <EditDeviceDetailsModal
                device={deviceToEdit}
                onCancel={() => {
                  setDeviceToEdit(undefined);
                }}
                onUpdate={onDeviceDetailsUpdate}
              />
            )}
            {deviceToDelete && DevicePermissions.DeleteDevice(deviceToDelete) && (
              <ConfirmModal
                size='small'
                header='Delete Device?'
                content={
                  <div>
                    Deleting this device will remove it from the Niagara Cloud and permanently delete all data
                    associated with this device. <strong>This action cannot be undone.</strong>
                  </div>
                }
                onCancel={() => setDeviceToDelete(undefined)}
                onConfirm={() => onConfirmDeleteDevice()}
                confirmButtonContent='Delete'
              />
            )}
          </>
        ) : (
          <div className='device-list-empty'>
            <div className='device-list-empty-title'>No Devices Registered</div>
            <div className='device-list-empty-text'>
              Go to the devices registration page to generate a registration link
            </div>
            <NiagaraButton2
              onClick={() => {
                setProjectToDelete(currentProject);
              }}
              content='Delete Project'
              disabled={!currentProject}
              type='primary-link'
            />
            {projectToDelete && (
              <DeleteProjectModal
                projectToDelete={projectToDelete}
                onClose={() => {
                  setProjectToDelete(undefined);
                }}
              />
            )}
          </div>
        )}
      </Status>
    </NiagaraCard>
  );
}

function deviceNameRenderer(
  device: Device,
  highlightedDeviceId: number | undefined,
  enableDeviceDetailsPage: boolean,
  setCurrentDevice: (device: DeviceWithProjectIdAndSubscriptions) => void
) {
  return (
    <>
      {device.id === highlightedDeviceId && <span id='highlighted-device' />}
      <ScrollIntoView enable={device.id === highlightedDeviceId} />
      {enableDeviceDetailsPage ? (
        <NavLink className='navbar-item' to='devicedetails' onClick={() => setCurrentDevice(device)}>
          {device.deviceName}
        </NavLink>
      ) : (
        device.deviceName
      )}
    </>
  );
}
function Services({
  device,
  disabledDevicesforAlarms,
  setCurrentDevice,
}: {
  device: DeviceWithProjectIdAndSubscriptions;
  disabledDevicesforAlarms: string[];
  setCurrentDevice: (device: DeviceWithProjectIdAndSubscriptions) => void;
}) {
  const navigate = useNavigate();
  const deviceRemoteUrl = useMemo(() => getDeviceRemoteUrl(device.deviceUuid), [device.deviceUuid]);
  const { enableAlarmsPage } = useFlags();
  const hasService = (service: string) => device.subscriptions.find((o) => o.offeringCode === service);
  const { NiagaraRemote, NiagaraDataServices } = usePermissions();
  const isNiagaraRemotePermissionPermitted = NiagaraRemote.Connect(device);
  const isNiagaraRecoverPermissionPermitted = usePermissions().NiagaraRecover.View(device);
  const isDataServicesQueryPermitted = NiagaraDataServices.QueryModel(device);
  const alarmPermission = usePermissions().NiagaraAlarms.Query(device);

  return (
    <div className='subscribed-services'>
      <span className='nds-services'>
        <NiagaraTooltip
          className={`alternate-tooltip`}
          content='Alarms'
          position='bottom center'
          element={
            <ActiveEffectButton
              className='active-nds'
              type='primary-link'
              icon='Alert'
              iconSize={18}
              onClick={() => navigate(`alarms?projectId=${device.projectId}&deviceUuid=${device.deviceUuid}`)}
              disabled={
                !enableAlarmsPage ||
                !hasService('NDS') ||
                !alarmPermission ||
                disabledDevicesforAlarms.includes(device.deviceUuid)
              }
            />
          }
        />
        <NiagaraTooltip
          className={`alternate-tooltip`}
          content='Data Services'
          position='bottom center'
          element={
            <ActiveEffectButton
              className='active-nds'
              type='primary-link'
              icon='Trend'
              iconSize={18}
              disabled={!hasService('NDS') || !isDataServicesQueryPermitted}
              onClick={() => {
                setCurrentDevice(device);
                navigate('dataservice');
              }}
            />
          }
        />
      </span>

      <NiagaraTooltip
        className='alternate-tooltip'
        content='Remote'
        position='bottom center'
        element={
          <ActiveEffectButton
            className='backup-link'
            type='primary-link'
            icon='Link'
            iconSize={18}
            disabled={!deviceRemoteUrl || !hasService('Remote') || !isNiagaraRemotePermissionPermitted}
            onClick={() => {
              const { complete } = tagTimedEvent('Niagara Remote');
              complete();
              window.open(deviceRemoteUrl, '_blank', 'noopener');
            }}
          />
        }
      />
      <NiagaraTooltip
        className='alternate-tooltip'
        content='Recover'
        position='bottom center'
        element={
          <ActiveEffectButton
            className='backup-link'
            type='primary-link'
            icon='UploadCloud'
            iconSize={18}
            disabled={!hasService('Recover') || !isNiagaraRecoverPermissionPermitted}
            onClick={() => {
              setCurrentDevice(device);
              navigate('savedbackups');
            }}
          />
        }
      />
    </div>
  );
}
